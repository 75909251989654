<template>
  <section>
    <!-- Fixed navbar -->
    <nav class="navbar navbar-expand-md navbar-light fixed-top top-bar">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-md-center"
        id="navbarCollapse"
      >
        <ul class="navbar-nav">
          <div v-for="item in items" :key="item">
            <li class="nav-item active" v-if="item.sub == 'No'">
              <router-link
                class="nav-link nav-active text-center"
                :to="item.to"
              >
                <span :class="item.icon"></span> {{ item.label }}
              </router-link>
            </li>
            <li class="nav-item dropdown" v-if="item.sub != 'No'">
              <span
                class="nav-link dropdown-toggle"
                id="dropdown08"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span :class="item.icon"></span> {{ item.label }}
              </span>
              <div class="dropdown-menu" aria-labelledby="dropdown08">
                <span v-for="dropItem in item.sub" :key="dropItem">
                  <router-link class="dropdown-item p-py-2" :to="dropItem.to"
                    ><span :class="dropItem.icon"></span>
                    {{ dropItem.label }}</router-link
                  >
                </span>
              </div>
            </li>
          </div>
          <li class="nav-item dropdown">
            <span
              class="nav-link dropdown-toggle"
              id="dropdown08"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="pi pi-fw pi-cog"></span> Settings
            </span>
            <div class="dropdown-menu" aria-labelledby="dropdown08">
              <span v-for="dropItem in dropDownList" :key="dropItem">
                <router-link class="dropdown-item p-py-2" :to="dropItem.to"
                  ><span :class="dropItem.icon"></span>
                  {{ dropItem.label }}</router-link
                >
              </span>
              <span class="dropdown-item p-py-2" @click="logOut"
                ><span class="pi pi-fw pi-power-off"></span> Logout</span
              >
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TabMenu from "primevue/tabmenu";
import router from "../router";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";

@Options({
  components: {
    TabMenu,
  },
})
export default class Header extends Vue {
  private toast;
  private items = [
    {
      label: "Dashboard",
      icon: "pi pi-fw pi-home",
      to: "/store/dashboard",
      sub: "No",
    },
    {
      label: "Associate",
      icon: "pi pi-fw pi-user",
      to: "/login",
      sub: [
        {
          label: "Schedule Assistant",
          icon: "pi pi-fw pi-clock",
          to: "/store/schedule",
        },
        {
          label: "Hours Approval",
          icon: "pi pi-fw pi-check-circle",
          to: "/store/hours-approval",
        },
      ],
    },
    {
      label: "Check in",
      icon: "pi pi-fw pi-check",
      to: "/check-in",
      sub: "No",
    },
    {
      label: "Login/Logout",
      icon: "pi pi-fw pi-lock",
      to: "/store/attendance",
      sub: "No",
    },
    {
      label: "Backroom",
      icon: "pi pi-fw pi-table",
      to: "/backroom",
      sub: "No",
    },
    {
      label: "Transactions",
      icon: "pi pi-fw pi-window-maximize",
      to: "/process/transactions",
      sub: "No",
    },
  ];

  private dropDownList = [
    {
      label: "Cash Register",
      icon: "pi pi-fw pi-money-bill",
      to: "/store/cash-register",
    },
    {
      label: "Initialization",
      icon: "pi pi-fw pi-plus-circle",
      to: "/store/initialization",
    },
    {
      label: "Store Expense",
      icon: "pi pi-fw pi-dollar",
      to: "/store/store-expense",
    },
    {
      label: "Reports",
      icon: "pi pi-fw pi-chart-bar",
      to: "/store/initialization",
    },
  ];

  created() {
    this.toast = new Toaster();
  }

  logOut() {
    const store = useStore();

    store.dispatch(ActionTypes.AUTH_LOGOUT, "");
    const res = {
      alert: "info",
      msg: "Signout successfully",
    };
    this.toast.handleResponse(res);
    router.push({ path: "/login" });
  }
}
</script>

<style scoped>
.navbar {
  padding: 0.2rem 0rem 0rem 0rem;
}
.top-bar {
  border-top: 2px solid #1463a5;
}

.navbar-light {
  z-index: 1;
  background-color: #fff;
}

.nav-item {
  margin: 0px 5px;
  width: 140px;
}

.router-link-active {
  border: 2px solid #ccc;
  border-radius: 5px;
}
</style>
