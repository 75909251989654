<template>
  <Dialog
    v-model:visible="dueDateDialog"
    :style="{ width: '50vw' }"
    :maximizable="false"
    :closable="false"
    position="top"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-d-flex p-jc-end">
      <div class="p-mr-2">
        <Button
          class="p-button-primary"
          icon="pi pi-check-circle"
          label="Done"
          @click="emitDueDateDetail()"
        />
      </div>
      <div class="">
        <Button
          class="p-button-danger"
          icon="pi pi-times"
          label="Cancel"
          @click="emitCancelDueDate()"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h5 class="p-mt-3">
          <i class="pi pi-calendar"></i>
          Set Order Due Date & Time {{ dateValue }}
        </h5>
        <div class="p-d-flex p-jc-between">
          <div class="p-mb-2 p-mr-2 pull-right">
            <vue-cal
              hide-view-selector
              v:disable-views="['years', 'year', 'week']"
              :time="true"
              :min-date="minDateValue"
              @cell-focus="parseDate($event)"
              :dblclickToNavigate="false"
              active-view="month"
              class="vuecal--rounded-theme vuecal--blue-theme"
              style="height: 63vh"
              xsmall
            >
            </vue-cal>
          </div>
          <div class="p-mb-2 p-mr-2 pull-right">
            <h5 class="date-time-clock-hrs-heading">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              Available Hours
            </h5>
            <div class="clock-hours-timming">
              <h5 @click="setDueTime('08:00')" class="date-time-clock-hrs">
                08:00 AM
              </h5>
              <h5 @click="setDueTime('09:00')" class="date-time-clock-hrs">
                09:00 AM
              </h5>
              <h5 @click="setDueTime('10:00')" class="date-time-clock-hrs">
                10:00 AM
              </h5>
              <h5 @click="setDueTime('11:00')" class="date-time-clock-hrs">
                11:00 AM
              </h5>
              <h5 @click="setDueTime('12:00')" class="date-time-clock-hrs">
                12:00 PM
              </h5>
              <h5 @click="setDueTime('13:00')" class="date-time-clock-hrs">
                01:00 PM
              </h5>
              <h5 @click="setDueTime('14:00')" class="date-time-clock-hrs">
                02:00 PM
              </h5>
              <h5 @click="setDueTime('15:00')" class="date-time-clock-hrs">
                03:00 PM
              </h5>
              <h5 @click="setDueTime('16:00')" class="date-time-clock-hrs">
                04:00 PM
              </h5>
              <h5 @click="setDueTime('17:00')" class="date-time-clock-hrs">
                05:00 PM
              </h5>
              <h5 @click="setDueTime('18:00')" class="date-time-clock-hrs">
                06:00 PM
              </h5>
              <h5 @click="setDueTime('19:00')" class="date-time-clock-hrs">
                07:00 PM
              </h5>
              <h5 @click="setDueTime('20:00')" class="date-time-clock-hrs">
                08:00 PM
              </h5>
              <h5 @click="setDueTime('21:00')" class="date-time-clock-hrs">
                09:00 PM
              </h5>
            </div>
            <input
              type="time"
              name="get_the_time"
              id="get_the_time"
              class="set_the_time_value form-control"
              v-model="timeValue"
            />
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";
import moment from "moment";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.dueDateDialog = obj.dueDateDialog;
    },
  },
  components: {
    VueCal,
  },
  emits: ["emitdueDateEvent"],
})
export default class DueDate extends Vue {
  private toast;
  private dueDateDialog = false;
  private itemDetail;
  private checkTxn;
  private dateValue = "";
  private timeValue = '';
  private express = false;
  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  get minDateValue() {
    return new Date();
  }

  parseDate(date) {
    if (date != "") {

      const d1 =  moment(String(this.minDateValue)).format("YYYY-MM-DD");
      const d2 =  moment(String(date)).format("YYYY-MM-DD");

      if(d1 <= d2)
      {
        this.dateValue = d2;
      }
      else
      {
        this.toast.showInfo('You cannot set a previous date');
      }
    }
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  emitDueDateDetail() {
    if (this.dateValue == "" || this.timeValue == '') {
      this.toast.showWarning("Please set both due Date & Time");
    } else {
      this.$emit("emitdueDateEvent", [
        this.dateValue,
        this.timeValue,
        "setDate",
      ]);
      this.dueDateDialog = false;
    }
  }

  emitCancelDueDate() {
    this.dateValue = "";
    this.timeValue = '';

    this.$emit("emitdueDateEvent", [
      this.dateValue,
      this.timeValue,
      "closeDialog",
    ]);
    this.dueDateDialog = false;
  }

  setDueTime(dueTime) {
    this.timeValue = dueTime;
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }
}
</script>

<style scoped>
</style>
