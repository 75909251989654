
import { Options, Vue } from "vue-class-component";
import TabMenu from "primevue/tabmenu";
import router from "../router";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";

@Options({
  components: {
    TabMenu,
  },
})
export default class Header extends Vue {
  private toast;
  private items = [
    {
      label: "Dashboard",
      icon: "pi pi-fw pi-home",
      to: "/store/dashboard",
      sub: "No",
    },
    {
      label: "Associate",
      icon: "pi pi-fw pi-user",
      to: "/login",
      sub: [
        {
          label: "Schedule Assistant",
          icon: "pi pi-fw pi-clock",
          to: "/store/schedule",
        },
        {
          label: "Hours Approval",
          icon: "pi pi-fw pi-check-circle",
          to: "/store/hours-approval",
        },
      ],
    },
    {
      label: "Check in",
      icon: "pi pi-fw pi-check",
      to: "/check-in",
      sub: "No",
    },
    {
      label: "Login/Logout",
      icon: "pi pi-fw pi-lock",
      to: "/store/attendance",
      sub: "No",
    },
    {
      label: "Backroom",
      icon: "pi pi-fw pi-table",
      to: "/backroom",
      sub: "No",
    },
    {
      label: "Transactions",
      icon: "pi pi-fw pi-window-maximize",
      to: "/process/transactions",
      sub: "No",
    },
  ];

  private dropDownList = [
    {
      label: "Cash Register",
      icon: "pi pi-fw pi-money-bill",
      to: "/store/cash-register",
    },
    {
      label: "Initialization",
      icon: "pi pi-fw pi-plus-circle",
      to: "/store/initialization",
    },
    {
      label: "Store Expense",
      icon: "pi pi-fw pi-dollar",
      to: "/store/store-expense",
    },
    {
      label: "Reports",
      icon: "pi pi-fw pi-chart-bar",
      to: "/store/initialization",
    },
  ];

  created() {
    this.toast = new Toaster();
  }

  logOut() {
    const store = useStore();

    store.dispatch(ActionTypes.AUTH_LOGOUT, "");
    const res = {
      alert: "info",
      msg: "Signout successfully",
    };
    this.toast.handleResponse(res);
    router.push({ path: "/login" });
  }
}
