<template>
  <Dialog
    v-model:visible="couponDialog"
    :style="{ width: '100vw' }"
    :maximizable="false"
    :closable="false"
    position="top"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-d-flex p-jc-end">
      <div class="p-mr-2">
        <Button
          class="p-button-success"
          icon="pi pi-check-circle"
          label="Apply Coupon on Receipt"
          @click="emitCoupon()"
        />
      </div>
      <div class="">
        <Button
          class="p-button-danger"
          icon="pi pi-times"
          label="Cancel"
          @click="emitCancelCoupon()"
        />
      </div>
    </div>
    <div class="row p-mt-3">
      <div class="col-md-12">
        <InputText
          v-on:keyup.enter="scanCoupon()"
          v-model="scanCouponCode"
          autoFocus
          placeholder="Search OR Scan Coupon then Press ENTER KEY"
        />
      </div>
    </div>
    <div class="row p-p-3">
      <div
        class="col-md-3 coupon-wrapper"
        v-for="coupon in filteredCoupons"
        :key="coupon"
      >
        <h5 class="p-m-0">
          {{ coupon.discountName }}
          <span class="statusTag pull-right">
            {{ formatAmount(coupon.discount) }} {{ coupon.discountMethod }}
          </span>
        </h5>
        <p class="p-p-0 p-m-0">
          Start From <b> {{ formatDate(coupon.startsDate) }} </b>
        </p>
        <p class="p-p-0 p-m-0">
          Valid Till <b> {{ formatDate(coupon.endDate) }} </b>
        </p>
        <input
          type="checkbox"
          :disabled="
            getCouponStatus(coupon.endDate) == 'expired' ? true : false
          "
          v-model="chosenCoupon"
          :value="coupon"
          class="coupon-checkbox p-mt-2 p-mb-2"
        />
        Choose Coupon

        <span
          :class="{
            statusExpired: getCouponStatus(coupon.endDate) == 'expired',
            statusActive: getCouponStatus(coupon.endDate) == 'active',
          }"
          class="pull-right p-mt-2"
          >{{ getCouponStatus(coupon.endDate).toUpperCase() }}</span
        >
      </div>
    </div>
    <div class="row">
      <h4 class="coupon-heading col-md-12" style="background-color: #004c97">
        <i class="pi pi-file-o"></i> OR Custom Coupon
      </h4>
    </div>
    <div class="row p-p-3">
      <div class="col-md-3 custom-wrapper">
        <div class="p-field">
          <label for="Priority">Coupon Name</label>
          <InputText v-model="couponName" placeholder="Custom Coupon" />
        </div>
        <div class="p-field">
          <label for="Priority">Method</label>
          <select v-model="couponMethod" class="form-control">
            <option value="$">Amount</option>
            <option value="%">Percentage</option>
          </select>
        </div>
        <div class="p-field">
          <label for="Priority">Coupon Value</label>
          <InputText v-model="couponValue" placeholder="0" />
        </div>
        <div class="p-field">
          <Button
            class="p-button-success"
            label="Add Coupon"
            icon="pi pi-check-circle"
            @click="addCustomCoupon()"
          />
        </div>
      </div>
      <div class="col-md-9">
        <h4 class="p-pb-2">Applied Coupons</h4>
        <template v-if="chosenCoupon.length > 0">
          <span
            class="applied-coupons"
            v-for="(chip, index) in chosenCoupon"
            :key="chip"
          >
            {{ chip.discountName }}
            <b> {{ chip.discountMethod }} {{ formatAmount(chip.discount) }} </b>
            <i @click="removeChip(index)" class="pi pi-times"></i>
          </span>
        </template>
        <h5 class="text-center" v-if="chosenCoupon.length == 0">
          <i>No Coupon is applied</i>
        </h5>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";
import moment from "moment";

import { CouponTypes } from "../pages/checkIn/ICheckin";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.couponDialog = obj.couponDialog;
      this.getCoupons(obj.couponList);
    },
  },
  components: {},
  emits: ["emitCouponEvent"],
})
export default class Coupons extends Vue {
  private toast;
  private scanCouponCode = "";
  private couponName = "";
  private couponMethod = "";
  private couponValue = 0;
  private couponDialog = false;
  private filteredCoupons: CouponTypes[] = [];
  private chosenCoupon: CouponTypes[] = [];
  private couponList: CouponTypes[] = [];
  private checkTxn;
  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  emitCoupon() {
    if (this.chosenCoupon.length > 1) {
      this.toast.showWarning(
        "You cannot add multiple coupons on single receipt"
      );
    } else if (this.chosenCoupon.length == 0) {
      this.toast.showWarning("Please choose any coupon to apply");
    } else {
      this.$emit("emitCouponEvent",this.chosenCoupon);
      this.couponDialog = false;
    }
  }

  emitCancelCoupon() {
    this.chosenCoupon = [];
    this.filteredCoupons = [];
    this.$emit("emitCouponEvent",this.chosenCoupon);
    this.couponDialog = false;
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("dddd, DD-MM-YYYY");
    }
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  removeChip(index) {
    this.chosenCoupon.splice(index, 1);
  }

  getCoupons(couponList) {

    this.couponList = [];

    couponList.forEach((e) => {
      const coup: CouponTypes = {
        discountId: e.id,
        discountName: e.discountName,
        discountMethod: "%",
        discount: e.discountPercentage,
        startsDate: e.startsDate,
        endDate: e.endDate,
        discountBarcode: e.discountBarcode,
        description: e.description,
      };
      this.couponList.push(coup);
    });
  }

  addCustomCoupon() {
    const coup: CouponTypes = {
      discountId: "custom-coupon",
      discountName: this.couponName,
      discountMethod: this.couponMethod,
      discount: this.couponValue,
      startsDate: "",
      endDate: "",
      discountBarcode: "",
      description: "",
    };

    this.chosenCoupon.push(coup);

    this.couponName = "";
    this.couponMethod = "";
    this.couponValue = 0;
  }

  getCouponStatus(endDate) {
    const d1 = moment(String(new Date())).format("YYYY-MM-DD");
    const d2 = moment(String(endDate)).format("YYYY-MM-DD");

    if (d1 <= d2) {
      return "active";
    } else {
      return "expired";
    }
  }

  scanCoupon() {
    if (this.scanCouponCode != "") {
      this.filteredCoupons = [];
      this.couponList.filter((e) => {
        const searched = e.discountName
          .toLowerCase()
          .search(this.scanCouponCode);

        if (e.discountBarcode == this.scanCouponCode || searched != -1) {
          this.filteredCoupons.push(e);
        }
      });
    } else {
      this.toast.showInfo("Please scan any coupon");
    }

    this.scanCouponCode = "";
  }
}
</script>

<style scoped>
.coupon-checkbox {
  width: 30px;
  height: 30px;
}

.custom-wrapper {
  background-color: #eee;
  color: #333;
  width: 100%;
  padding: 20px;
  border: 1px solid #fff;
  border-radius: 5px;
}

.coupon-wrapper {
  background-color: #46a93d;
  color: #fff;
  width: 100%;
  padding: 20px;
  border: 1px solid #fff;
}
.coupon-heading {
  background-color: #004c97;
  margin: 1px 0px;
  color: #fff;
  padding: 10px 5px;
}

.applied-coupons {
  background-color: #eee;
  border-radius: 15px;
  margin: 5px;
  padding: 5px;
}

.applied-coupons i {
  color: #dc3545;
  cursor: pointer;
  margin-left: 5px;
}

.scan-barcode {
  width: 40%;
}

.statusExpired {
  background-color: #dc3545;
  border-radius: 15px;
  color: #fff;
  font-size: 14px;
  padding: 2px 10px;
}

.statusActive {
  background-color: yellow;
  border-radius: 15px;
  color: #333;
  font-size: 14px;
  padding: 2px 10px;
}

.statusTag {
  background-color: #004c97;
  border-radius: 15px;
  color: #fff;
  font-size: 14px;
  padding: 2px 10px;
}
</style>
