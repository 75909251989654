
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";
import moment from "moment";

import { CouponTypes } from "../pages/checkIn/ICheckin";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.couponDialog = obj.couponDialog;
      this.getCoupons(obj.couponList);
    },
  },
  components: {},
  emits: ["emitCouponEvent"],
})
export default class Coupons extends Vue {
  private toast;
  private scanCouponCode = "";
  private couponName = "";
  private couponMethod = "";
  private couponValue = 0;
  private couponDialog = false;
  private filteredCoupons: CouponTypes[] = [];
  private chosenCoupon: CouponTypes[] = [];
  private couponList: CouponTypes[] = [];
  private checkTxn;
  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  emitCoupon() {
    if (this.chosenCoupon.length > 1) {
      this.toast.showWarning(
        "You cannot add multiple coupons on single receipt"
      );
    } else if (this.chosenCoupon.length == 0) {
      this.toast.showWarning("Please choose any coupon to apply");
    } else {
      this.$emit("emitCouponEvent",this.chosenCoupon);
      this.couponDialog = false;
    }
  }

  emitCancelCoupon() {
    this.chosenCoupon = [];
    this.filteredCoupons = [];
    this.$emit("emitCouponEvent",this.chosenCoupon);
    this.couponDialog = false;
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("dddd, DD-MM-YYYY");
    }
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  removeChip(index) {
    this.chosenCoupon.splice(index, 1);
  }

  getCoupons(couponList) {

    this.couponList = [];

    couponList.forEach((e) => {
      const coup: CouponTypes = {
        discountId: e.id,
        discountName: e.discountName,
        discountMethod: "%",
        discount: e.discountPercentage,
        startsDate: e.startsDate,
        endDate: e.endDate,
        discountBarcode: e.discountBarcode,
        description: e.description,
      };
      this.couponList.push(coup);
    });
  }

  addCustomCoupon() {
    const coup: CouponTypes = {
      discountId: "custom-coupon",
      discountName: this.couponName,
      discountMethod: this.couponMethod,
      discount: this.couponValue,
      startsDate: "",
      endDate: "",
      discountBarcode: "",
      description: "",
    };

    this.chosenCoupon.push(coup);

    this.couponName = "";
    this.couponMethod = "";
    this.couponValue = 0;
  }

  getCouponStatus(endDate) {
    const d1 = moment(String(new Date())).format("YYYY-MM-DD");
    const d2 = moment(String(endDate)).format("YYYY-MM-DD");

    if (d1 <= d2) {
      return "active";
    } else {
      return "expired";
    }
  }

  scanCoupon() {
    if (this.scanCouponCode != "") {
      this.filteredCoupons = [];
      this.couponList.filter((e) => {
        const searched = e.discountName
          .toLowerCase()
          .search(this.scanCouponCode);

        if (e.discountBarcode == this.scanCouponCode || searched != -1) {
          this.filteredCoupons.push(e);
        }
      });
    } else {
      this.toast.showInfo("Please scan any coupon");
    }

    this.scanCouponCode = "";
  }
}
