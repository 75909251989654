
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import Customer from "../service/Customer";
@Options({
  props: {
    customerDetail: Object,
  },
  watch: {
    customerDetail(obj) {
      if (obj.status == "openCustomer") {
        this.openDialog();
      } else if (obj.status == "updateCustomer") {
        this.editIemDialog(obj.customerId);
      } else {
        this.dialogTitle = "";
      }
      this.permission = obj.permission;
      this.dialogTitle = obj.title;
    },
  },
  emits: ["updateCustomerStatus"],
})
export default class CustomerDialog extends Vue {
  private toast;
  private submitted = false;
  private productDialog = false;
  private dialogTitle = "";
  private permission = "";
  private customer;
  private managerList;
  private storeList;
  private regionsList;
  private hideDisableFields = false;

  private customerClass = [
    { value: "Entry Level" },
  ];

  private genderClass = [
    { value: "Male" },
    { value: "Female" },
  ];

  private cusType = [
    { value: "Regular" },
  ];

  private customers = {
    id: "",
    customerId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone1: "",
    homePhone: "",
    misc: "",
    customerType: {},
    address: "",
    city: "",
    zipCode: "",
    complex: "",
    stateProvince: {},
    branchId: 0,
    provincialRate: "",
    canadaRate:"",
    exempt1: "",
    exempt2: "",
    keyTags: "",
    urgentMemo: "",
    birthday: "",
    gender: {},
    maleNeck: 0,
    maleFullChest: 0,
    maleFrontChestArea: 0,
    maleBackChest: 0,
    maleFullShoulderWidth: 0,
    maleBlazerSleeve: 0,
    maleBicep: 0,
    maleWrist: 0,
    maleTrouserOutseam: 0,
    maleThigh:0,
    maleKnee:0,
    maleHipSeat:0,
    maleHalfBackLength:0,
    femaleNeck:0,
    femaleOverBrust:0,
    femaleBrust:0,
    femaleUnderBrust:0,
    femaleWaist:0,
    femaleHips:0,
    femaleNeckHeal:0,
    femaleArmLenght:0,
    femaleShoulderSeam:0,
    femaleBicep:0,
    femaleForearm:0,
    femaleWrist:0,
    cusMood:"green",
    cusComments:"",
    customerReviews:"",
    class:{},
    pointFeature:"",
    promoNotification: "",
    pointEarned: "",
    emailNotification: "",
    textMessage: "",
    cusCredit:0,
    ytdVisits:0,
    totalVisits:0,
    totalService:"",
    totalDues:"",
    lastVisit:"",
    lastDues:0,
    customerSince:"",
    customerServices:"",
    status:"Active"
  };
  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.customer = new Customer();
    this.toast = new Toaster();
  }

  mounted()
  {
    this.loadOpenDialogData();
  }

   formatCurrency(value) {
      return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
   }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.submitted = false;
    this.productDialog = true;
    this.loadOpenDialogData();
  }

  loadOpenDialogData()
  {
    this.customer.openDialog().then((data) => {
      this.regionsList = data.regions;
      this.customers.customerId = data.customer_id;
      this.customers.branchId = data.branch_id;
    });
  }

  //ADD REVIEWS
  addReview() {
    if(this.customers.cusComments == "") {
        const message = {
          'msg' : 'Empty Comment!',
          'alert': 'danger'  
        };
       this.toast.handleResponse(message);
    } else {
      this.customer.saveCustomerReviews(this.customers).then((res) => {
        console.log(res);
        this.customers.customerReviews = res.customer_reviews[0].customer_reviews;
        const message = {
          'msg' : 'Review added successfully',
          'alert': 'info'  
        };
        this.customers.cusComments = "";
        this.toast.handleResponse(message);
      });
    }
  }

  //ADD CUSTOMER CREDIT
  addCustomerCredit() {
    if(this.customers.cusCredit == 0 ) {
        const message = {
          'msg' : 'Please enter amount',
          'alert': 'danger'  
        };
       this.toast.handleResponse(message);
    } else {
        this.customer.addCustomerCredit(this.customers).then((res) => {
        if(res == 'failed')
        {
          const message = {
            'msg' : 'Cannot occured any changes',
            'alert': 'danger'  
          };
          this.toast.handleResponse(message);
        } else {
          const message = {
            'msg' : 'Amount added successfully',
            'alert': 'info'  
          };
          this.toast.handleResponse(message);
          this.customers.pointEarned = this.customers.pointEarned + this.customers.cusCredit;
          this.customers.cusCredit = 0;
        }
      });
    }
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;

    if (this.customers.firstName.trim()) {
      if (this.customers.id != "") {
        this.customer.updateItem(this.customers).then((res) => {
          this.closeDialogBox();
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.customer.saveItem(this.customers).then((res) => {
          this.closeDialogBox();
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;

      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIemDialog(id) {
    this.submitted = false;
    this.hideDisableFields = true;

    if (id != "") {
      this.customer.getItem(id).then((res) => {
        if (res['cus_data'] != "") {
          console.log(res);
          
          const region = this.regionFilter(res['cus_data'][0].state);

          const customerType = this.customerTypeFilter(res['cus_data'][0].customer_type);

          const gender = this.genderFilter(res['cus_data'][0].gender);
          const custClass = this.classFilter(res['cus_data'][0].customer_class);

          this.customers.id =  res['cus_data'][0].customer_id;
          this.customers.customerId =  res['cus_data'][0].customer_id;
          this.customers.firstName =  res['cus_data'][0].first_name;
          this.customers.lastName =  res['cus_data'][0].last_name;
          this.customers.email =  res['cus_data'][0].email;
          this.customers.phone1 =  res['cus_data'][0].contact;
          this.customers.homePhone = res['cus_data'][0].phone;
          this.customers.misc =  res['cus_data'][0].company_name;
          this.customers.customerType =  customerType;
          this.customers.address =  res['cus_data'][0].address;
          this.customers.city =  res['cus_data'][0].city;
          this.customers.zipCode =  res['cus_data'][0].zip_code;
          this.customers.complex =  res['cus_data'][0].complex;
          this.customers.stateProvince =  region;
          this.customers.branchId =  res['cus_data'][0].branch_id;
          this.customers.provincialRate =  res['cus_data'][0].pst_exempt;
          this.customers.canadaRate =  res['cus_data'][0].hst_exempt;
          this.customers.exempt1 =  res['cus_data'][0].exempt_1;
          this.customers.exempt2 =  res['cus_data'][0].exempt_2;
          this.customers.keyTags =  res['cus_data'][0].key_tags;
          this.customers.urgentMemo =  res['cus_data'][0].urgent_memo;
          this.customers.birthday =  res['cus_data'][0].birthday;
          this.customers.gender = gender;
          this.customers.maleNeck =  res['cus_data'][0].male_neck
          this.customers.maleFullChest =  res['cus_data'][0].male_full_chest
          this.customers.maleFrontChestArea =  res['cus_data'][0].male_front_chest_area
          this.customers.maleBackChest =  res['cus_data'][0].male_back_chest
          this.customers.maleFullShoulderWidth =  res['cus_data'][0].male_full_shoulder
          this.customers.maleBlazerSleeve =  res['cus_data'][0].male_blazer_sleeve
          this.customers.maleBicep =  res['cus_data'][0].male_bicep
          this.customers.maleWrist =  res['cus_data'][0].male_wrist
          this.customers.maleTrouserOutseam =  res['cus_data'][0].male_trouser_outseam
          this.customers.maleThigh = res['cus_data'][0].male_thigh
          this.customers.maleKnee = res['cus_data'][0].male_knee
          this.customers.maleHipSeat = res['cus_data'][0].male_hips
          this.customers.maleHalfBackLength = res['cus_data'][0].male_half_back_lenght
          this.customers.femaleNeck = res['cus_data'][0].female_neck
          this.customers.femaleOverBrust = res['cus_data'][0].female_over_brust
          this.customers.femaleBrust = res['cus_data'][0].female_brust
          this.customers.femaleUnderBrust = res['cus_data'][0].female_under_bust
          this.customers.femaleWaist = res['cus_data'][0].female_waist
          this.customers.femaleHips = res['cus_data'][0].female_hips
          this.customers.femaleNeckHeal = res['cus_data'][0].female_neck_heel
          this.customers.femaleArmLenght = res['cus_data'][0].female_arm_length
          this.customers.femaleShoulderSeam = res['cus_data'][0].female_shoulder_seam
          this.customers.femaleBicep = res['cus_data'][0].female_bicep
          this.customers.femaleForearm = res['cus_data'][0].female_forearm
          this.customers.femaleWrist = res['cus_data'][0].female_wrist
          this.customers.customerReviews = res['cus_data'][0].customer_reviews;
          this.customers.class = custClass;
          this.customers.pointFeature = res['cus_data'][0].points_feature;
          this.customers.promoNotification =  res['cus_data'][0].promotion;
          this.customers.emailNotification =  res['cus_data'][0].enable_email;
          this.customers.textMessage =  res['cus_data'][0].text_msg;
          this.customers.pointEarned =   res.needle_points;
          this.customers.cusCredit =   0;
          this.customers.ytdVisits = res.ytd_visits;
          this.customers.totalVisits = res.total_visits;
          this.customers.totalService = res.total_service;
          this.customers.totalDues = res.total_dues;
          this.customers.lastVisit = res.last_visit;
          this.customers.lastDues = res.last_dues;
          this.customers.customerSince = res.customer_since;
          this.customers.customerServices = res.customer_services;
          this.customers.status =  res['cus_data'][0].status;
          this.productDialog = true;
        }
      });
    } else {
      this.toast.showError("Could not be able to load the record invalid ID");
    }
  }

  // //USED TO FILTER REGIONS
  regionFilter(res) {
    let region = {};
    this.regionsList.filter((elem) => {
      if (elem.region_id == res) {
        region = elem;
      }
    });
    return region;
  }

  //USED TO FILTER CUSTOMER TYPE
  customerTypeFilter(res) {
    let cusType = {};
    this.cusType.filter((elem) => {
      if (elem.value == res) {
        cusType = elem;
      }
    });
    return cusType;
  }

  //USED TO FILTER GENDER
  genderFilter(res) {
    let gender = {};
    this.genderClass.filter((elem) => {
      if (elem.value == res) {
        gender = elem;
      }
    });
    return gender;
  }

  //USED TO FILTER CLASS
  classFilter(res) {
    let Cusclass = {};
    this.customerClass.filter((elem) => {
      if (elem.value == res) {
        Cusclass = elem;
      }
    });
    return Cusclass;
  }

  clearItem() {
    this.hideDisableFields = false;
    this.customers.id =  "";
    this.customers.customerId =  "";
    this.customers.firstName =  "";
    this.customers.lastName =  "";
    this.customers.email =  "";
    this.customers.phone1 =  "";
    this.customers.homePhone =  "";
    this.customers.misc =  "";
    this.customers.customerType =  {};
    this.customers.address =  "";
    this.customers.city =  "";
    this.customers.zipCode =  "";
    this.customers.complex =  "";
    this.customers.stateProvince =  {};
    this.customers.branchId =  0;
    this.customers.provincialRate =  "";
    this.customers.canadaRate = "";
    this.customers.exempt1 =  "";
    this.customers.exempt2 =  "";
    this.customers.keyTags =  "";
    this.customers.urgentMemo =  "";
    this.customers.birthday =  "";
    this.customers.gender =  {};
    this.customers.maleNeck =  0;
    this.customers.maleFullChest =  0;
    this.customers.maleFrontChestArea =  0;
    this.customers.maleBackChest =  0;
    this.customers.maleFullShoulderWidth =  0;
    this.customers.maleBlazerSleeve =  0;
    this.customers.maleBicep =  0;
    this.customers.maleWrist =  0;
    this.customers.maleTrouserOutseam = 0;
    this.customers.maleThigh = 0;
    this.customers.maleKnee = 0;
    this.customers.maleHipSeat = 0;
    this.customers.maleHalfBackLength = 0;
    this.customers.femaleNeck = 0;
    this.customers.femaleOverBrust = 0;
    this.customers.femaleBrust = 0;
    this.customers.femaleUnderBrust = 0;
    this.customers.femaleWaist = 0;
    this.customers.femaleHips = 0;
    this.customers.femaleNeckHeal = 0;
    this.customers.femaleArmLenght = 0;
    this.customers.femaleShoulderSeam = 0;
    this.customers.femaleBicep = 0;
    this.customers.femaleForearm = 0;
    this.customers.femaleWrist = 0;
    this.customers.cusComments = "";
    this.customers.class = {};
    this.customers.pointFeature = "";
    this.customers.promoNotification =  "";
    this.customers.pointEarned =  "";
    this.customers.emailNotification =  "";
    this.customers.textMessage =  "";
    this.customers.cusCredit = 0;
    this.customers.ytdVisits = 0;
    this.customers.totalVisits = 0;
    this.customers.totalService = "";
    this.customers.totalDues = "";
    this.customers.lastVisit = "";
    this.customers.lastDues = 0;
    this.customers.customerSince = "";
    this.customers.customerServices = "";
  }

  closeDialogBox() {
    this.submitted = false;
    this.productDialog = false;
    this.clearItem();
    this.$emit("updateCustomerStatus");
  }
}
