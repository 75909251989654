
import { Options, Vue } from 'vue-class-component';
import Backrooom from "../service/Backrooom";
import moment from "moment";

@Options({
  components: {},
  props: {
    associateDetail: Object,
  },
  watch: {
    associateDetail(obj) {

      if(obj.status)
      {
        this.receiptID = obj.receiptID;
        this.openDialog();
      }
    }
  },
  emits: ["updateEmployeeStatus"],
})
export default class EmployeeBackroom extends Vue {

    private productDialog = false;
    private loading = false;
    private receiptID = '';
    private associateList = [];
    private backroomService;

    created()
    {
      this.backroomService = new Backrooom();
    }

    mounted()
    {
       // const fetchDate = "";
        //this.loadList(fetchDate);
    }

    openDialog()
    {
        this.productDialog = true;
        this.loading = true;
        this.backroomService.associateOrderPreview(this.receiptID).then((data) => {
        this.associateList = data;
        this.loading = false;
      });
    }

    closeDialogBox()
    {
        this.productDialog = false;
          this.$emit("updateEmployeeStatus");
    }

  formatDate(value) {
    if (value)
    {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value)
    {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }
}
