<template>
  <Dialog
    v-model:visible="productDialog"
    :style="{ width: '100vw' }"
    :maximizable="true"
    position="top"
    class="p-fluid"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
        {{ dialogTitle }}
      </h4>
    </template>
    <div class="p-grid">
      <div class="p-col-12">
        <TabView>
          <TabPanel header="Basic">
            <div class="p-grid">
              <div class="p-col-6">
                <div class="p-field">
                  <label for="customerID">Customer ID</label>
                  <InputText
                    id="customerID"
                    disabled="true"
                    v-model="customers.customerId"
                  />
                </div>
                <div class="p-field">
                  <label for="firstName">First Name / Company Name</label>
                  <InputText
                    id="firstName"
                    v-model="customers.firstName"
                    :class="{ 'p-invalid': submitted && !customers.firstName }"
                  />
                  <small
                    class="p-invalid"
                    v-if="submitted && !customers.firstName"
                    >First Name is required.</small
                  >
                </div>
                <div class="p-field">
                  <label for="lastName">Last Name</label>
                  <InputText
                    id="lastName"
                    v-model="customers.lastName"
                  />
                </div>
                <div class="p-field">
                  <label for="email">Email Address</label>
                  <InputText
                    id="email"
                    v-model="customers.email"
                  />
                </div>
              </div>
              <!--.p-col-6-->

              <div class="p-col-6">
                <div class="p-field">
                  <label for="phone">Mobile/Contact No</label>
                  <InputText
                    id="phone"
                    :useGrouping="false"
                    v-model="customers.phone1"
                  />
                </div>
                <div class="p-field">
                  <label for="homePhone">Home Phone</label>
                  <InputText id="homePhone" :useGrouping="false" v-model="customers.homePhone" />
                </div>
                <div class="p-field">
                  <label for="misc">Misc</label>
                  <InputText
                    id="misc"
                    v-model="customers.misc"
                  />
                </div>
                <div class="p-field">
                  <label for="customerType">Customer Type</label>
                  <Dropdown
                    id="customerType"
                    v-model="customers.customerType"
                    :options="cusType"
                    optionLabel="value"
                  />
                </div>
              </div>
              <!--.p-col-6-->
            </div>
          </TabPanel>
          <TabPanel header="Address">
            <div class="p-grid">
              <div class="p-col-12">
                <div class="p-field">
                  <label for="address">Address</label>
                  <InputText id="address" v-model="customers.address" />
                </div>
              </div>
              <!--.p-col-12-->
              <div class="p-col-6">
                <div class="p-field">
                  <label for="city">City</label>
                  <InputText id="city" v-model="customers.city" />
                </div>
                <div class="p-field">
                  <label for="zipcode">Zip/Post Code</label>
                  <InputText id="zipcode" v-model="customers.zipCode" />
                </div>
                <div class="p-field">
                  <label for="complex">Complex</label>
                  <InputText id="complex" v-model="customers.complex" />
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6">
                <div class="p-field">
                  <label for="stateProvince">State/Province</label>
                  <Dropdown
                    id="stateProvince"
                    v-model="customers.stateProvince"
                    :options="regionsList"
                    :filter="true"
                  >
                    <template #value="slotProps">
                      <span>
                        Province: {{ slotProps.value.province_name }} | Region:
                        {{ slotProps.value.region_name }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <span>
                        {{ slotProps.option.province_name }} |
                        {{ slotProps.option.region_name }}
                      </span>
                    </template>
                  </Dropdown>
                </div>
                <div class="p-field">
                  <label for="branchId">Branch ID (Stitch It Corporate Office)</label>
                  <InputNumber :useGrouping="false" id="branchId" v-model="customers.branchId"  disabled="true"/>
                </div>
              </div>
              <!--.p-col-6-->
            </div>
          </TabPanel>
          <TabPanel header="Other Details">
            <div class="p-grid">
              <div class="p-col-6">
                <div class="p-field">
                <div class="form-check ">
                  <input style="width: 30px; height: 30px;" class="form-check-input" type="checkbox" v-model="customers.provincialRate" value="Provincial_Rate" id="provincialRate">
                  <label class="form-check-label ml-3" for="provincialRate">
                    Provincial Rate
                  </label>
                </div>
                </div>
                
                <div class="p-field">
                  <label for="exempt1">Exempt #</label>
                  <InputText id="exempt1" v-model="customers.exempt1" />
                </div>
                <div class="p-field">
                  <label for="keyTags">Key Tags</label>
                  <InputText id="keyTags" v-model="customers.keyTags" />
                </div>
                <div class="p-field">
                  <label for="urgentMemo">Urgent Memo</label>
                  <InputText id="urgentMemo" v-model="customers.urgentMemo" />
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6">
                <div class="p-field">
                 <div class="form-check">
                    <input class="form-check-input" style="width: 30px; height: 30px;" type="checkbox" v-model="customers.canadaRate" value="Canada_Rate" id="canadaRate">
                    <label class="form-check-label ml-3" for="canadaRate">
                      Canada Rate
                    </label>
                  </div>
                </div>
                <div class="p-field">
                  <label for="exempt2">Exempt #</label>
                  <InputText id="exempt2" v-model="customers.exempt2" />
                </div>
                <div class="p-field">
                  <label for="birthday">Birthday</label>
                  <input type="date" class="form-control" v-model="customers.birthday" />
                </div>
              </div>
              <!--.p-col-6-->
            </div>
          </TabPanel>
          <TabPanel header="Measurments">
            <div class="p-grid">
              <div class="p-col-12">
                <label for="gender">Gender</label>
                <Dropdown
                    id="gender"
                    v-model="customers.gender"
                    :options="genderClass"
                    optionLabel="value"
                  />
              </div>
             
                <div class="p-col-6" v-if="customers.gender.value == 'Male'">
                  <div class="p-field">
                    <label for="neck">Neck</label>
                    <InputNumber :useGrouping="false" mode="decimal" :minFractionDigits="1" id="neck" v-model="customers.maleNeck"/>
                  </div>
                  <div class="p-field">
                    <label for="fullChest">Full Chest</label>
                    <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"  id="fullChest" v-model="customers.maleFullChest"/>
                  </div>
                  <div class="p-field">
                    <label for="frontChestArea">Front Chest Area</label>
                    <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"  id="frontChestArea" v-model="customers.maleFrontChestArea"/>
                  </div>
                  <div class="p-field">
                    <label for="frontChestArea">Back Chest</label>
                    <InputNumber   :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="frontChestArea" v-model="customers.maleBackChest"/>
                  </div>
                  <div class="p-field">
                    <label for="fullShoulderWidth">Full Shoulder Width</label>
                    <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="fullShoulderWidth" v-model="customers.maleFullShoulderWidth"/>
                  </div>
                  <div class="p-field">
                    <label for="blazerSleeve">Blazer Sleeve</label>
                    <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="blazerSleeve" v-model="customers.maleBlazerSleeve"/>
                  </div>
                  <div class="p-field">
                    <label for="bicep">Bicep</label>
                    <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="bicep" v-model="customers.maleBicep"/>
                  </div>
                </div>
                <!--.p-col-6-->
                <div class="p-col-6" v-if="customers.gender.value == 'Male'">
                  <div class="p-field">
                    <label for="wrist">Wrist</label>
                    <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="wrist" v-model="customers.maleWrist"/>
                  </div>
                  <div class="p-field">
                    <label for="trouserOutseam">Trouser Outseam</label>
                    <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="trouserOutseam" v-model="customers.maleTrouserOutseam"/>
                  </div>
                  <div class="p-field">
                    <label for="thigh">Thigh</label>
                    <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="thigh" v-model="customers.maleThigh"/>
                  </div>
                  <div class="p-field">
                    <label for="knee">Knee</label>
                    <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="knee" v-model="customers.maleKnee"/>
                  </div>
                  <div class="p-field">
                    <label for="hipsSeat">Hips/Seat</label>
                    <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="hipsSeat" v-model="customers.maleHipSeat"/>
                  </div>
                  <div class="p-field">
                    <label for="halfBackLength">Half Back Length</label>
                    <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="halfBackLength" v-model="customers.maleHalfBackLength"/>
                  </div>
                </div>
              <!--.p-col-6-->
              <div class="p-col-6" v-if="customers.gender.value == 'Female'">
                <div class="p-field">
                  <label for="neck">Neck</label>
                  <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="neck" v-model="customers.femaleNeck"/>
                </div>
                <div class="p-field">
                  <label for="fullChest">Over Brust</label>
                  <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="fullChest" v-model="customers.femaleOverBrust"/>
                </div>
                <div class="p-field">
                  <label for="frontChestArea">Brust</label>
                  <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="frontChestArea" v-model="customers.femaleBrust"/>
                </div>
                <div class="p-field">
                  <label for="frontChestArea">Under Bust</label>
                  <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="frontChestArea" v-model="customers.femaleUnderBrust"/>
                </div>
                <div class="p-field">
                  <label for="fullShoulderWidth">Waist</label>
                  <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="fullShoulderWidth" v-model="customers.femaleWaist"/>
                </div>
                <div class="p-field">
                  <label for="blazerSleeve">Hips</label>
                  <InputNumber   :useGrouping="false" mode="decimal" :minFractionDigits="1"  id="blazerSleeve" v-model="customers.femaleHips"/>
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6" v-if="customers.gender.value == 'Female'">
                <div class="p-field">
                  <label for="wrist">Neck Heel</label>
                  <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="wrist" v-model="customers.femaleNeckHeal"/>
                </div>
                <div class="p-field">
                  <label for="trouserOutseam">Arm Length</label>
                  <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="trouserOutseam" v-model="customers.femaleArmLenght"/>
                </div>
                <div class="p-field">
                  <label for="thigh">Shoulder Seam</label>
                  <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="thigh" v-model="customers.femaleShoulderSeam"/>
                </div>
                <div class="p-field">
                  <label for="knee">Bicep</label>
                  <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="knee" v-model="customers.femaleBicep"/>
                </div>
                <div class="p-field">
                  <label for="hipsSeat">Forearm</label>
                  <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="hipsSeat" v-model="customers.femaleForearm"/>
                </div>
                <div class="p-field">
                  <label for="halfBackLength">Wrist</label>
                  <InputNumber  :useGrouping="false" mode="decimal" :minFractionDigits="1"   id="halfBackLength" v-model="customers.femaleWrist"/>
                </div>
              </div><!--.p-col-6-->
            </div>
          </TabPanel>
          <TabPanel header="Note">
            <div class="p-grid">
              <div class="p-col-12">
                <div class="p-field-radiobutton">
                    <span class="badge badge-success px-3 py-2 mx-2"><RadioButton id="city1" name="city" value="green" v-model="customers.cusMood" /></span>
                    <span class="badge badge-danger px-3 py-2 mx-2"><RadioButton id="city2" name="city" value="red" v-model="customers.cusMood" /></span>
                </div>
              </div> <!--.p-col-12-->
              <div class="p-col-12">
                <div class="p-field">
                  <div class="form-group">
                    <label for="comment">Comments</label>
                    <textarea class="form-control" v-model="customers.cusComments" id="comment" rows="4"></textarea>
                  </div>
                </div>
                <div class="p-grid" v-if="hideDisableFields == true">
                  <div class="p-col"></div>
                  <div class="p-col-fixed" style="width:190px"><Button label="Add Reviews" @click="addReview" icon="pi pi-plus-circle" iconPos="left" /></div>
                </div>
              </div> <!--.p-col-12-->
              <div class="p-col-12" v-if="hideDisableFields == true">
                <DataTable :value="customers.customerReviews" class="p-datatable-striped p-datatable-gridlines p-datatable-sm">
                    <Column field="customer_mood" header="Emoji"></Column>
                    <Column field="note" header="Customer reviews"></Column>
                    <Column field="visit_date" header="Date"></Column>
                    <Column field="visit_time" header="Time"></Column>
                </DataTable>
              </div> <!--.p-col-12-->
            </div>
          </TabPanel>
          <TabPanel header="Histroy" :disabled="!hideDisableFields">
            <div class="p-grid">
              <div class="p-col-6">
                <div class="p-field">
                  <label for="halfBackLength">YTD Visits</label>
                  <InputNumber :useGrouping="false" id="halfBackLength" disabled="true" v-model="customers.ytdVisits"/>
                </div>
                <div class="p-field">
                  <label for="halfBackLength">YTD Services</label>
                  <div class="p-inputgroup">
                    <InputNumber :useGrouping="false" id="halfBackLength" class="mr-2" disabled="true"/>
                    <InputNumber :useGrouping="false" id="halfBackLength" disabled="true" />
                  </div>
                </div>
                <div class="p-field">
                  <label for="halfBackLength">Customer Since</label>
                  <InputText id="halfBackLength" disabled="true" v-model="customers.customerSince"/>
                </div>
                <div class="p-field">
                  <label for="halfBackLength">Total Due</label>
                  <InputText id="halfBackLength" disabled="true" v-model="customers.totalDues"/>
                </div>
              </div> <!--.p-col-6-->
              <div class="p-col-6">
                <div class="p-field">
                  <label for="halfBackLength">Total Visits</label>
                  <InputNumber :useGrouping="false" id="halfBackLength" disabled="true" v-model="customers.totalVisits"/>
                </div>
                <div class="p-field">
                  <label for="halfBackLength">Total Services</label>
                  <div class="p-inputgroup">
                    <InputNumber  :useGrouping="false" id="halfBackLength" class="mr-2" disabled="true" v-model="customers.totalService"/>
                    <InputNumber :useGrouping="false" id="halfBackLength" disabled="true" v-model="customers.totalVisits"/>
                  </div>
                </div>
                <div class="p-field">
                  <label for="halfBackLength">Last Visits</label>
                  <InputText id="halfBackLength" disabled="true" v-model="customers.lastVisit"/>
                </div>
                <div class="p-field">
                  <label for="halfBackLength">Over Due( > 30 days)</label>
                  <InputNumber :useGrouping="false" id="halfBackLength" disabled="true" v-model="customers.lastDues"/>
                </div>
              </div> <!--.p-col-6-->
              <div class="p-col-12">
                <DataTable :value="customers.customerServices" class="p-datatable-striped p-datatable-gridlines p-datatable-sm">
                    <Column field="name" header="Services"></Column>
                    <Column field="date" header="Last visit Date"></Column>
                    <Column field="qty" header="Qty"></Column>
                    <Column header="Total">
                      <template #body="slotProps">
                         {{formatCurrency(slotProps.data.total_amount)}}
                      </template>
                    </Column>
                </DataTable>
              </div> <!--.p-col-12-->
            </div>
          </TabPanel>
          <TabPanel header="Marketing">
            <div class="p-grid">
              <div class="p-col-12">
                <label for="class">Class</label>
                <Dropdown
                    id="class"
                    v-model="customers.class"
                    :options="customerClass"
                     optionLabel="value"
                  />
              </div>
              <div class="p-col-6">
                <div class="p-field my-3">
                  <i class="pi pi-arrow-circle-right "></i> <label for="enablePoints">Enable Points Feature</label>
                </div>
                <div class="p-field my-3">
                  <i class="pi pi-arrow-circle-right "></i> <label for="promoNotification">Enable This Customer to Receive Promotion Notification</label>
                </div>
                <div class="p-field my-3">
                  <i class="pi pi-arrow-circle-right "></i>  <label for="pointsearned">Points Earned ($)</label>
                </div>
                <div class="p-field my-3" v-if="hideDisableFields == true">
                  <i class="pi pi-arrow-circle-right "></i>  <label for="earned">Add Store Credit ($)</label>
                </div>
                <div class="p-field my-3">
                  <i class="pi pi-arrow-circle-right "></i>  <label for="emailNotification">Enable This Customer to Receive Ready Invoice Email Notification</label>
                </div>
                <div class="p-field my-3">
                  <i class="pi pi-arrow-circle-right "></i>  <label for="textMessage">Enable This Customer to Receive Ready Invoice Text Message(SMS)</label>
                </div>
              </div><!--.p-col-6-->
              <div class="p-col-6">
                <div class="p-field">
                  <input style="width: 30px; height: 30px;"  v-model="customers.enablePoints" type="checkbox" value="yes" id="enablePoints">
                </div>
                <div class="p-field">
                  <input style="width: 30px; height: 30px;" v-model="customers.promoNotification" type="checkbox" value="yes" id="promoNotification">
                </div>
                <div class="p-field">
                  <InputText   class="w-25" disabled="true" id="pointsearned" v-model="customers.pointEarned"/>
                </div>
                <div class="p-field" v-if="hideDisableFields == true">
                    <InputNumber :useGrouping="false" id="storeCredit" class="w-25 mr-2" mode="currency" v-model="customers.cusCredit" currency="USD" locale="en-US"  />
                    <Button label="Add" style="width:6rem;" icon="pi pi-plus-circle" @click="addCustomerCredit" iconPos="left"/>
                </div>
                <div class="p-field">
                   <input style="width: 30px; height: 30px;"  v-model="customers.emailNotification" type="checkbox" value="yes" id="emailNotification">
                </div>
                <div class="p-field">
                  <input style="width: 30px; height: 30px;" v-model="customers.textMessage" type="checkbox" value="yes" id="textMessage">
                </div>
              </div><!--.p-col-6-->
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
    <template #footer>
      <Button
        v-if="permission == 'yes'"
        label="Save"
        icon="pi pi-check"
        class="p-button-success"
        @click="saveItem"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        class="p-button-danger"
        @click="closeDialogBox"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import Customer from "../service/Customer";
@Options({
  props: {
    customerDetail: Object,
  },
  watch: {
    customerDetail(obj) {
      if (obj.status == "openCustomer") {
        this.openDialog();
      } else if (obj.status == "updateCustomer") {
        this.editIemDialog(obj.customerId);
      } else {
        this.dialogTitle = "";
      }
      this.permission = obj.permission;
      this.dialogTitle = obj.title;
    },
  },
  emits: ["updateCustomerStatus"],
})
export default class CustomerDialog extends Vue {
  private toast;
  private submitted = false;
  private productDialog = false;
  private dialogTitle = "";
  private permission = "";
  private customer;
  private managerList;
  private storeList;
  private regionsList;
  private hideDisableFields = false;

  private customerClass = [
    { value: "Entry Level" },
  ];

  private genderClass = [
    { value: "Male" },
    { value: "Female" },
  ];

  private cusType = [
    { value: "Regular" },
  ];

  private customers = {
    id: "",
    customerId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone1: "",
    homePhone: "",
    misc: "",
    customerType: {},
    address: "",
    city: "",
    zipCode: "",
    complex: "",
    stateProvince: {},
    branchId: 0,
    provincialRate: "",
    canadaRate:"",
    exempt1: "",
    exempt2: "",
    keyTags: "",
    urgentMemo: "",
    birthday: "",
    gender: {},
    maleNeck: 0,
    maleFullChest: 0,
    maleFrontChestArea: 0,
    maleBackChest: 0,
    maleFullShoulderWidth: 0,
    maleBlazerSleeve: 0,
    maleBicep: 0,
    maleWrist: 0,
    maleTrouserOutseam: 0,
    maleThigh:0,
    maleKnee:0,
    maleHipSeat:0,
    maleHalfBackLength:0,
    femaleNeck:0,
    femaleOverBrust:0,
    femaleBrust:0,
    femaleUnderBrust:0,
    femaleWaist:0,
    femaleHips:0,
    femaleNeckHeal:0,
    femaleArmLenght:0,
    femaleShoulderSeam:0,
    femaleBicep:0,
    femaleForearm:0,
    femaleWrist:0,
    cusMood:"green",
    cusComments:"",
    customerReviews:"",
    class:{},
    pointFeature:"",
    promoNotification: "",
    pointEarned: "",
    emailNotification: "",
    textMessage: "",
    cusCredit:0,
    ytdVisits:0,
    totalVisits:0,
    totalService:"",
    totalDues:"",
    lastVisit:"",
    lastDues:0,
    customerSince:"",
    customerServices:"",
    status:"Active"
  };
  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.customer = new Customer();
    this.toast = new Toaster();
  }

  mounted()
  {
    this.loadOpenDialogData();
  }

   formatCurrency(value) {
      return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
   }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.submitted = false;
    this.productDialog = true;
    this.loadOpenDialogData();
  }

  loadOpenDialogData()
  {
    this.customer.openDialog().then((data) => {
      this.regionsList = data.regions;
      this.customers.customerId = data.customer_id;
      this.customers.branchId = data.branch_id;
    });
  }

  //ADD REVIEWS
  addReview() {
    if(this.customers.cusComments == "") {
        const message = {
          'msg' : 'Empty Comment!',
          'alert': 'danger'  
        };
       this.toast.handleResponse(message);
    } else {
      this.customer.saveCustomerReviews(this.customers).then((res) => {
        console.log(res);
        this.customers.customerReviews = res.customer_reviews[0].customer_reviews;
        const message = {
          'msg' : 'Review added successfully',
          'alert': 'info'  
        };
        this.customers.cusComments = "";
        this.toast.handleResponse(message);
      });
    }
  }

  //ADD CUSTOMER CREDIT
  addCustomerCredit() {
    if(this.customers.cusCredit == 0 ) {
        const message = {
          'msg' : 'Please enter amount',
          'alert': 'danger'  
        };
       this.toast.handleResponse(message);
    } else {
        this.customer.addCustomerCredit(this.customers).then((res) => {
        if(res == 'failed')
        {
          const message = {
            'msg' : 'Cannot occured any changes',
            'alert': 'danger'  
          };
          this.toast.handleResponse(message);
        } else {
          const message = {
            'msg' : 'Amount added successfully',
            'alert': 'info'  
          };
          this.toast.handleResponse(message);
          this.customers.pointEarned = this.customers.pointEarned + this.customers.cusCredit;
          this.customers.cusCredit = 0;
        }
      });
    }
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;

    if (this.customers.firstName.trim()) {
      if (this.customers.id != "") {
        this.customer.updateItem(this.customers).then((res) => {
          this.closeDialogBox();
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.customer.saveItem(this.customers).then((res) => {
          this.closeDialogBox();
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;

      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIemDialog(id) {
    this.submitted = false;
    this.hideDisableFields = true;

    if (id != "") {
      this.customer.getItem(id).then((res) => {
        if (res['cus_data'] != "") {
          console.log(res);
          
          const region = this.regionFilter(res['cus_data'][0].state);

          const customerType = this.customerTypeFilter(res['cus_data'][0].customer_type);

          const gender = this.genderFilter(res['cus_data'][0].gender);
          const custClass = this.classFilter(res['cus_data'][0].customer_class);

          this.customers.id =  res['cus_data'][0].customer_id;
          this.customers.customerId =  res['cus_data'][0].customer_id;
          this.customers.firstName =  res['cus_data'][0].first_name;
          this.customers.lastName =  res['cus_data'][0].last_name;
          this.customers.email =  res['cus_data'][0].email;
          this.customers.phone1 =  res['cus_data'][0].contact;
          this.customers.homePhone = res['cus_data'][0].phone;
          this.customers.misc =  res['cus_data'][0].company_name;
          this.customers.customerType =  customerType;
          this.customers.address =  res['cus_data'][0].address;
          this.customers.city =  res['cus_data'][0].city;
          this.customers.zipCode =  res['cus_data'][0].zip_code;
          this.customers.complex =  res['cus_data'][0].complex;
          this.customers.stateProvince =  region;
          this.customers.branchId =  res['cus_data'][0].branch_id;
          this.customers.provincialRate =  res['cus_data'][0].pst_exempt;
          this.customers.canadaRate =  res['cus_data'][0].hst_exempt;
          this.customers.exempt1 =  res['cus_data'][0].exempt_1;
          this.customers.exempt2 =  res['cus_data'][0].exempt_2;
          this.customers.keyTags =  res['cus_data'][0].key_tags;
          this.customers.urgentMemo =  res['cus_data'][0].urgent_memo;
          this.customers.birthday =  res['cus_data'][0].birthday;
          this.customers.gender = gender;
          this.customers.maleNeck =  res['cus_data'][0].male_neck
          this.customers.maleFullChest =  res['cus_data'][0].male_full_chest
          this.customers.maleFrontChestArea =  res['cus_data'][0].male_front_chest_area
          this.customers.maleBackChest =  res['cus_data'][0].male_back_chest
          this.customers.maleFullShoulderWidth =  res['cus_data'][0].male_full_shoulder
          this.customers.maleBlazerSleeve =  res['cus_data'][0].male_blazer_sleeve
          this.customers.maleBicep =  res['cus_data'][0].male_bicep
          this.customers.maleWrist =  res['cus_data'][0].male_wrist
          this.customers.maleTrouserOutseam =  res['cus_data'][0].male_trouser_outseam
          this.customers.maleThigh = res['cus_data'][0].male_thigh
          this.customers.maleKnee = res['cus_data'][0].male_knee
          this.customers.maleHipSeat = res['cus_data'][0].male_hips
          this.customers.maleHalfBackLength = res['cus_data'][0].male_half_back_lenght
          this.customers.femaleNeck = res['cus_data'][0].female_neck
          this.customers.femaleOverBrust = res['cus_data'][0].female_over_brust
          this.customers.femaleBrust = res['cus_data'][0].female_brust
          this.customers.femaleUnderBrust = res['cus_data'][0].female_under_bust
          this.customers.femaleWaist = res['cus_data'][0].female_waist
          this.customers.femaleHips = res['cus_data'][0].female_hips
          this.customers.femaleNeckHeal = res['cus_data'][0].female_neck_heel
          this.customers.femaleArmLenght = res['cus_data'][0].female_arm_length
          this.customers.femaleShoulderSeam = res['cus_data'][0].female_shoulder_seam
          this.customers.femaleBicep = res['cus_data'][0].female_bicep
          this.customers.femaleForearm = res['cus_data'][0].female_forearm
          this.customers.femaleWrist = res['cus_data'][0].female_wrist
          this.customers.customerReviews = res['cus_data'][0].customer_reviews;
          this.customers.class = custClass;
          this.customers.pointFeature = res['cus_data'][0].points_feature;
          this.customers.promoNotification =  res['cus_data'][0].promotion;
          this.customers.emailNotification =  res['cus_data'][0].enable_email;
          this.customers.textMessage =  res['cus_data'][0].text_msg;
          this.customers.pointEarned =   res.needle_points;
          this.customers.cusCredit =   0;
          this.customers.ytdVisits = res.ytd_visits;
          this.customers.totalVisits = res.total_visits;
          this.customers.totalService = res.total_service;
          this.customers.totalDues = res.total_dues;
          this.customers.lastVisit = res.last_visit;
          this.customers.lastDues = res.last_dues;
          this.customers.customerSince = res.customer_since;
          this.customers.customerServices = res.customer_services;
          this.customers.status =  res['cus_data'][0].status;
          this.productDialog = true;
        }
      });
    } else {
      this.toast.showError("Could not be able to load the record invalid ID");
    }
  }

  // //USED TO FILTER REGIONS
  regionFilter(res) {
    let region = {};
    this.regionsList.filter((elem) => {
      if (elem.region_id == res) {
        region = elem;
      }
    });
    return region;
  }

  //USED TO FILTER CUSTOMER TYPE
  customerTypeFilter(res) {
    let cusType = {};
    this.cusType.filter((elem) => {
      if (elem.value == res) {
        cusType = elem;
      }
    });
    return cusType;
  }

  //USED TO FILTER GENDER
  genderFilter(res) {
    let gender = {};
    this.genderClass.filter((elem) => {
      if (elem.value == res) {
        gender = elem;
      }
    });
    return gender;
  }

  //USED TO FILTER CLASS
  classFilter(res) {
    let Cusclass = {};
    this.customerClass.filter((elem) => {
      if (elem.value == res) {
        Cusclass = elem;
      }
    });
    return Cusclass;
  }

  clearItem() {
    this.hideDisableFields = false;
    this.customers.id =  "";
    this.customers.customerId =  "";
    this.customers.firstName =  "";
    this.customers.lastName =  "";
    this.customers.email =  "";
    this.customers.phone1 =  "";
    this.customers.homePhone =  "";
    this.customers.misc =  "";
    this.customers.customerType =  {};
    this.customers.address =  "";
    this.customers.city =  "";
    this.customers.zipCode =  "";
    this.customers.complex =  "";
    this.customers.stateProvince =  {};
    this.customers.branchId =  0;
    this.customers.provincialRate =  "";
    this.customers.canadaRate = "";
    this.customers.exempt1 =  "";
    this.customers.exempt2 =  "";
    this.customers.keyTags =  "";
    this.customers.urgentMemo =  "";
    this.customers.birthday =  "";
    this.customers.gender =  {};
    this.customers.maleNeck =  0;
    this.customers.maleFullChest =  0;
    this.customers.maleFrontChestArea =  0;
    this.customers.maleBackChest =  0;
    this.customers.maleFullShoulderWidth =  0;
    this.customers.maleBlazerSleeve =  0;
    this.customers.maleBicep =  0;
    this.customers.maleWrist =  0;
    this.customers.maleTrouserOutseam = 0;
    this.customers.maleThigh = 0;
    this.customers.maleKnee = 0;
    this.customers.maleHipSeat = 0;
    this.customers.maleHalfBackLength = 0;
    this.customers.femaleNeck = 0;
    this.customers.femaleOverBrust = 0;
    this.customers.femaleBrust = 0;
    this.customers.femaleUnderBrust = 0;
    this.customers.femaleWaist = 0;
    this.customers.femaleHips = 0;
    this.customers.femaleNeckHeal = 0;
    this.customers.femaleArmLenght = 0;
    this.customers.femaleShoulderSeam = 0;
    this.customers.femaleBicep = 0;
    this.customers.femaleForearm = 0;
    this.customers.femaleWrist = 0;
    this.customers.cusComments = "";
    this.customers.class = {};
    this.customers.pointFeature = "";
    this.customers.promoNotification =  "";
    this.customers.pointEarned =  "";
    this.customers.emailNotification =  "";
    this.customers.textMessage =  "";
    this.customers.cusCredit = 0;
    this.customers.ytdVisits = 0;
    this.customers.totalVisits = 0;
    this.customers.totalService = "";
    this.customers.totalDues = "";
    this.customers.lastVisit = "";
    this.customers.lastDues = 0;
    this.customers.customerSince = "";
    this.customers.customerServices = "";
  }

  closeDialogBox() {
    this.submitted = false;
    this.productDialog = false;
    this.clearItem();
    this.$emit("updateCustomerStatus");
  }
}
</script>
